.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;

    .navTitle {
      flex-basis: 80%;
      font-weight: 600;
    }

    .navBtn {
      display: flex;

      .navButton {
        flex: 1 1 0%;
        margin: 0 0.5rem;
      }
    }
  }

  .card {
    padding: 1.5rem 3rem 2rem 1.5rem;
    border-radius: 1.5rem;

    .itemTitle {
      margin-left: 1rem;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .item {
      display: flex;
      align-items: center;
      width: 100%;

      .itemSubTitle {
        font-weight: 600;
      }

      .itemVisiblePage {
        flex-basis: 20%;
        font-weight: 600;

        .itemVisiblePageTitle {
          margin-bottom: 0.75rem;
        }
      }

      .itemPermission {
        flex-basis: 100%;
        font-weight: 600;

        .itemPermissionTitle {
          margin-bottom: 0.75rem;
        }

        .itemPermissionsForm {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
        }
      }

      .itemInput {
        display: flex;
        flex: 1;
      }
    }

    .lastItem {
      margin-bottom: 10rem;
    }
  }
}

.roleListItem {
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;

  .itemVisiblePage {
    flex-basis: 20%;
    font-weight: 600;
  }
  .itemVisiblePageTitle {
    margin-bottom: 0.75rem;
  }
}
