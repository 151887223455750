.home {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100vw - 15rem);
  height: calc(100vh - 3.5rem);

  .nav {
    margin: 1rem 5rem 6rem;
    display: flex;
    flex: row;

    .navBar,
    .navBarActive {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
      color: #808080;
      font-size: 1.2rem;
      text-decoration: none;
    }

    .navBarActive {
      color: #000000;
      border-bottom: 0.2rem solid #000000;
      text-decoration: none;
    }
  }

  .outlet {
    flex: 1;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
