.username {
  cursor: auto;
}

.iconBox {
  background-color: #ddd;
  border-radius: 50%;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
}
