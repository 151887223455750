.pageWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 35rem;
  padding: 5rem;
  padding-bottom: 7.3rem;
  position: relative;

  .progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffffd8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .addBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .addTitleBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        margin-top: 0.625rem;
        font-size: 1.5rem;
      }
    }

    .apiKey {
      margin-top: 1.25rem;
    }

    .description {
      margin-top: 1.25rem;
    }

    .signInButton {
      margin-top: 1.25rem;
    }
  }
}
