.tableWrap {
  display: flex;
  justify-content: center;
  width: 55em;
  padding: 2.5rem 5rem;

  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.deletedColor {
  background-color: #ff6961e6;
}
