.separate {
  padding: 0 0.35rem;
}

.image {
  width: 10rem;
  height: 6rem;
  object-fit: cover;
  padding: 0.25rem;
}

.mark {
  color: #2c75cf7f;
  margin-top: 0.75rem;
  font-family: "Tahoma";
}

.deleteIconImage {
  cursor: pointer;
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
}

.deleteIconFile {
  cursor: pointer;
}

.box {
  display: flex;
  flex-direction: column;
  width: 100%;

  .selectWrap {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;

    .inputWrap {
      flex: 1;
      min-width: 12rem;
      margin: 0.8rem 0;

      .corpInput {
        :global(input[type="button" i]) {
          text-align: left;
          cursor: pointer;
        }
      }
    }

    .groupLabel {
      margin: 0.5rem;
      padding-top: 0.5rem;
      border-top: 0.1rem solid #eee;
    }

    .noneGroupLabel {
      height: 0;
      margin: 0;
      padding: 0;
    }
  }

  .typeShow {
    display: flex;
    flex-direction: column;
    flex: 1;

    .rowBox {
      flex: 1;
      display: flex;
      flex-direction: row;

      .titleAndContentinputBox {
        width: 100%;
        display: flex;
        flex-direction: column;

        .input {
          flex: 1;
          margin: 0.8rem 0;
        }

        .mentionsInputWrap {
          position: absolute;
          top: 0.3rem;
          left: 0.7rem;
          background: #f2f3f4;
          padding: 0 0.2rem;
          height: 1rem;
          line-height: 1rem;
          z-index: 1;
          font-size: 0.12rem;
          color: #666;
        }

        .MentionsInput {
          width: 100%;
          height: 12rem;
          margin: 0.8rem 0;
          display: inline-flex;

          :global(textarea) {
            padding: 1rem;
            border: 0.01rem solid #b8b8b8;
            border-radius: 0.25rem;
          }

          &.borderColor {
            :global(textarea) {
              outline: #2c75cf auto 0.01rem;
              border-radius: 0.25rem;
            }
          }
        }
      }

      .uploadBtnBox {
        margin-top: 0.8rem;
        margin-bottom: 0.7rem;
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      .information {
        display: flex;
        flex-direction: row;

        .sendBox {
          flex: 1;

          .picturebackground {
            flex: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-radius: 0.5rem;
            border: 0.1rem solid rgb(207, 200, 200);
            padding: 0.25rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

.contentTextarea {
  textarea {
    overflow-y: auto !important;
  }
}
