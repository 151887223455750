.tableWrap {
  width: 105rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;

  .alert {
    position: absolute;
    width: 20rem;
    top: 2rem;
    left: calc((100vw - 20rem) / 2);
  }

  .selectStyle {
    vertical-align: bottom !important;
  }

  .tableBoxWrap {
    flex: 1;
    display: flex;
    justify-content: center;

    .tableBox {
      padding: 1rem 0 0 0;
      width: 100%;
      display: flex;

      .createNoticeWrap {
        display: flex;
        justify-content: flex-end;
        margin: 1rem 0rem;
        font-size: 0.9rem;

        .createNotice:hover {
          cursor: pointer;
        }
      }

      .title {
        text-align: center;
        width: 100%;
        white-space: normal;
        word-wrap: break-word;
      }

      .tooltip {
        text-align: center;
        width: 100%;
        padding: 0 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .operate {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .text:hover {
          cursor: pointer;
        }
      }
    }
  }

  .boxButtonWrap {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    margin-right: 1rem;

    .boxButton {
      line-height: 0.9rem;
      margin: 0rem 2rem;
    }
  }

  .deleteTextWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
  }

  .deleteBoxButtonWrap {
    display: flex;
    padding-bottom: 0.9rem;

    .deleteBoxButton {
      line-height: 0.9rem;
      margin: 0rem 2rem;
    }
  }
}

.deletedColor {
  background-color: #ff6961e6;
}
