$navBackgroundColor: #ffffff;
$aColor: #000000;
// $aClickColor: #1677ff;
$aClickColor: #30cea3;

.main {
  display: flex;
  flex-direction: row;
  background-color: #f2f3f4;
  height: 100vh;

  a {
    text-decoration: none;
  }

  .sideBar {
    background: $navBackgroundColor;
    width: 15rem;
    text-align: center;

    .navBar {
      margin-top: 8rem;

      .item {
        font-size: 1rem;
        margin: 2rem 0;
        display: flex;
        position: relative;
        justify-content: center;

        .itemNone {
          color: $aColor;
          text-decoration: none;
          position: relative;
        }

        .itemClick {
          color: $aClickColor;
          text-decoration: none;
          position: relative;
        }

        .iconTitleContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          width: max-content;
        }

        .title {
          margin-left: 0.4rem;
          font-size: 1rem;
          display: flex;
        }
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 15rem);
    height: 100vh;

    .contextUpper {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem 2rem;
    }

    .contextLower {
      flex: 1;
    }
  }
}

.childrenItem {
  margin-top: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.childrenItemNone {
  color: $aColor;
  text-decoration: none;
  position: absolute;
  left: 1.8rem;
  white-space: nowrap;
}

.childrenItemClick {
  color: $aClickColor;
  position: absolute;
  left: 1.8rem;
  text-decoration: none;
  white-space: nowrap;
}

.childrenSettingIcon {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}
