.meetingBtn {
  display: flex;
  color: #368aef;
  word-break: keep-all;
  cursor: pointer;
}

.container {
  height: 100%;
}

.appointmentMeeting {
  background-color: #f2f3f4;
  padding-top: 1rem;
}

.appointmentPerson {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.fromDataItem {
  width: 100%;
}

.participantData {
  display: flex;
  flex-basis: 35%;
  align-items: center;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  min-height: 3rem;
  max-height: 3rem;
  border: 0.1rem solid #d8d9d9;
  border-radius: 0.3rem;
  background-color: #e8e8e8;
}

.showParticipantData {
  display: flex;
  align-items: center;
  flex-basis: 35%;
  min-width: 9.5rem;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  min-height: 3rem;
  max-height: 3rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border: 0.1rem solid #d8d9d9;
  border-radius: 0.3rem;
}
.participantName {
  margin-left: 1rem;
  max-width: 6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.addParticipant {
  min-height: 3rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  flex-basis: 35%;
  min-width: 9.5rem;
  min-height: 3rem;
  max-height: 3rem;
  border-radius: 0.3rem;
  border: 0.1rem solid #d8d9d9;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  color: #368aef;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.addParticipantIcon {
  padding: 0.1rem;
  background-color: #2196f338;
  border-radius: 0.3rem;
  margin-right: 0.5rem;
}

.annexBtn {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  border: 0.1rem solid #d8d9d9;
}

.annexBtnText {
  padding: 0.4rem 4rem;
  border-right: 0.1rem solid #d8d9d9;
}

.annexBtnIcon {
  color: rgba(0, 0, 0, 0.54);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.editableTextarea {
  display: flex;
  flex-direction: column;
}

.toolbar {
  border-bottom: 0.1rem solid #d8d9d9;
}

.editorText {
  height: 6rem;
}

.setUpBtn {
  border: 0.1rem solid #d8d9d9 !important;
  background-color: transparent !important;
  border-radius: 0.3rem;
  padding: 0.4rem 4rem;
  color: #000 !important;
  cursor: pointer;
  width: fit-content;
}

.delFileIcon {
  font-size: 1rem;
  color: rgb(234, 91, 91);
  margin-left: 1.5rem;
}

.fileName {
  width: 6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.title {
  width: 6rem;
  min-width: 6rem;
  text-align: center;
  margin-right: 0.5rem;
}

.fromItem {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.participantDataBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 17rem;
  min-width: 13rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.enterpriseApplications {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.inputWrap {
  flex: 1 1;
  min-width: 12rem;
}

.widthFull {
  width: 100%;
  min-width: 12rem;
}

.selectGroupData {
  width: 100%;
  display: flex;
  align-items: center;
  min-width: 12rem;
}

.remindersEndDate {
  white-space: nowrap;
  margin-left: 1rem;
  margin-right: 1rem;
}

.seetingBtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.adminAndParticipantItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}

.meetingCheckBox {
  display: flex;
  margin-bottom: 0.5rem;
}

.meetingCodeCheck,
.meetingLinkCheck {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}
