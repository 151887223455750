.wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 65rem;
  margin: 0 auto;

  :global(input[type="button" i]) {
    text-align: left;
    cursor: pointer;
  }

  .inputGroup {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    .corpInput {
      flex: 1;

      &.inputButton {
        :global(.MuiInputBase-multiline),
        :global(textarea) {
          cursor: pointer;
          caret-color: transparent;
        }
      }
    }

    & > span {
      line-height: 1.5;
      width: 5rem;
      font-size: 1.2rem;
    }
  }

  .timeSelector {
    @extend .inputGroup;
    align-items: start;
    flex-direction: column;
  }
}

.editorBox {
  margin-top: 2rem;
  width: 100%;
  border: 0.01rem solid #ddd;
}

.dateSelector {
  display: flex;
}

.annexes {
  margin: 1.5rem -0.5rem -0.5rem;
}
