.sendMsgBox {
  display: flex;
  flex-direction: column;
  padding: 0 10rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex: 1;
  box-sizing: border-box;

  .selectInputBox {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    .corpInput {
      margin-bottom: 1rem;
      :global(input[type="button" i]) {
        text-align: left;
        cursor: pointer;
      }
    }
  }

  .checkboxAndUploadBox {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  .textarea {
    margin-top: 0.7rem;
    width: 100%;

    .multilineTextField {
      width: 100%;
    }
  }

  .dateInput {
    width: 12rem;
    height: 2rem;
  }

  .cycleSelectWrap {
    width: 100%;
  }

  .groupLabel {
    margin: 0.5rem;
    padding-top: 0.5rem;
    border-top: 0.1rem solid #eee;
  }

  .noneGroupLabel {
    height: 0;
    margin: 0;
    padding: 0;
  }
}
