.user {
  padding: 1.5rem 0;
  background-color: #f2f3f4;
  overflow-y: scroll;
  height: calc(100vh - 7rem);
  &::-webkit-scrollbar {
    display: none;
  }

  .accordion {
    background-color: #f2f3f4;
    box-shadow: none;

    .accordionSummary {
      margin: 0 1.5rem 0.5rem;
      background-color: #ffffff;
      border-radius: 0.2rem;

      .addButton {
        height: 1.5rem;
        margin-left: 1.5rem;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      }
      .listText {
        display: flex;
        align-items: center;
      }
    }

    .accordionDetails {
      margin: 0rem 3rem 0.5rem;
      height: 1rem;
      background-color: #ffffff;
      border-radius: 0.2rem;
    }
  }
  .accordion::before {
    background-color: #f2f3f4;
    box-shadow: none;
    border-width: none;
    border: #f2f3f4;
  }

  .registerButton {
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  .userListBox {
    max-height: 39rem;
    overflow-y: auto;
    position: relative;
  }

  .userListBox::-webkit-scrollbar {
    width: 0;
  }

  .loadingStyle {
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    top: 13rem;
    z-index: 9;
    font-size: 1.5rem;
    color: #535050;
    position: absolute;
  }

  .notData {
    text-align: center;
    margin-top: 13rem;
    color: #71717a;
  }
}
