.pageWrap {
  display: flex;
  margin-top: 5rem;
  justify-content: center;

  .loginBox {
    width: 23.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .signInTitleBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        margin-top: 0.625rem;
        font-size: 1.5rem;
      }
    }

    .signInUsername {
      margin-top: 1.25rem;
    }

    .signInPassword {
      margin-top: 1.25rem;
    }

    .signInButton {
      margin-top: 1.25rem;
    }
  }
}
