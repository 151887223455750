.iconWrap {
  display: flex;

  .folder {
    color: #1990ff;
    padding: 0.5rem 0.5rem 0 0;
  }

  .arrowIcon {
    font-size: 2rem !important;
    color: #888888;
    padding-top: 0.2rem;
  }
}
