body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:global(.MuiAutocomplete-root .MuiInputLabel-outlined),
:global(.MuiTextField-root .MuiInputLabel-outlined) {
  transition: 0s all;
  transform: translate(14px, -9px) scale(0.75);
}

:global(.MuiTextField-root legend),
:global(.MuiAutocomplete-root legend) {
  transition: 0s all;
  max-width: 100%;
}
